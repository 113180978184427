.footerH1Color{
    color: #8EA841;
    font-weight: normal;
    font-size: 26px;
  }
  .footerLink {
    display: block;
    width: 60px;
    height: 60px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #000000;
    border-radius: 6px;
}
hr {
  border: none;
  border-top: 3px double #8EA841;
  color: #8EA841;
  overflow: visible;
  /*text-align: center;*/
  height: 5px;
}

.listContentFooter{
  list-style-type: none;
}
