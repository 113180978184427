.list-notificacao{
  min-width: 400px;
  background: #ffffff;
}

.list-notificacao li{
   border-bottom : 1px #d8d8d8 solid;
   text-align    : justify;
   padding       : 5px 10px 5px 10px;
   cursor: pointer;
   font-size: 12px;
}

.list-notificacao li:hover{
background: #f1eeee;
}

.list-notificacao li:hover .exclusaoNotificacao{
display: block;
}

.list-notificacao li  p{
 color: black;
 width: 305px;
}

.list-notificacao li .exclusaoNotificacao{
    width: 25px;
    min-height: 40px;
    position: absolute;
    right: 0;
    display: none;
}

.list-notificacao .media img{
    width: 40px;
    height: 40px;
    float:left;
    margin-right: 10px;
}

.badgeAlert {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    background-color: #d9534f;
    border-radius: 10px;
    position: absolute;
    margin-top: -10px;
    margin-left: -10px
}


#headerLogo {
  max-width: 110px;
  max-height: 110px;
}

@media screen and (max-width: 1023px) {
  .nav-link,
  .btn {
   /* background-color: rgba(0, 0, 0, 0.8);*/

    /*width: 50%;*/
  }

}

.headerH1Color {
  color: #68c404;
}
#headerContent,
#headerImage {
  height: 620px;
}
#headerLeftText {
}

#headerImage {
  width: 630px;
}

#leftImage {
  width: 195px;
  height: 620px;
  background-size: 200px 580px;
  background-repeat: no-repeat;
}

#middleImage {
  width: 240px;
  height: 620px;
  background-size: 240px 620px;
  background-repeat: no-repeat;
}

#rightImage {
  position: relative;
  top: 15px;
  width: 195px;
  height: 605px;
  background-size: 180px 540px;
  background-repeat: no-repeat;
}

.btn-orange {
  background-color: #ff8c00;
  color: #fff;
  font-weight: bold;
}
